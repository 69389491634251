
//                                               name="friday"
//                                               placeholder="friday"
//                                             />
//                                           </Form.Group>
//                                           <Form.Group
//                                             className="popup-grp mb-3 px-3"
//                                             id="sat"
//                                             controlId="exampleForm.ControlInput1"
//                                           >
//                                             <div className="label-con1">6</div>
//                                             <Form.Label className="label-con1">Saturday</Form.Label>
//                                             <Form.Control
//                                               style={{ height: '40px' }}
//                                               className="popup-control"
//                                               type="number"
//                                               min={0}
//                                               value={field.saturday}
//                                               onChange={(event) => handleChange(index, event)}
//                                               name="saturday"
//                                               placeholder="saturday"
//                                             />
//                                           </Form.Group>
//                                           <Form.Group
//                                             className="popup-grp mb-3 px-3"
//                                             controlId="exampleForm.ControlInput1"
//                                           >
//                                             <div className="label-con1">7</div>
//                                             <Form.Label className="label-con1">Sunday</Form.Label>
//                                             <Form.Control
//                                               style={{ height: '40px' }}
//                                               className="popup-control"
//                                               type="number"
//                                               min={0}
//                                               value={field.sunday}
//                                               onChange={(event) => handleChange(index, event)}
//                                               name="sunday"
//                                               placeholder="sunday"
//                                             />
//                                           </Form.Group>
//                                           <Form.Group
//                                             className="popup-grp mb-3 px-3"
//                                             controlId="exampleForm.ControlInput1"
//                                           >
//                                             <div className="label-con1">8</div>
//                                             <Form.Label className="label-con1">Total</Form.Label>
//                                             <Form.Control
//                                               style={{ height: '40px' }}
//                                               className="popup-control"
//                                               type="total"
//                                               name="total"
//                                               min={1}
//                                               value={field.total}
//                                               onChange={(event) => handleChange(index, event)}
//                                               placeholder="total"
//                                             />
//                                           </Form.Group>
//                                         </Form>
//                                       </Modal.Body>
//                                       <Modal.Footer>
//                                         <Button
//                                           variant="secondary"
//                                           onClick={() => handleClose(index)}
//                                         >
//                                           Close
//                                         </Button>
//                                         {/* <Button variant="primary"  onClick={() => handleClose()}>
//                                           ok
//                                         </Button> */}
//                                       </Modal.Footer>
//                                     </Modal>
//                                   </div>
//                                 </td>

//                                 <td style={{}}>
//                                   <div className="form-area">
//                                     <input
                                     
//                                       value={field.rate}
//                                       onChange={(event) => handleChange(index, event)}
//                                       step="any"
//                                       name="rate"
//                                       type="number"
//                                       min={0}
//                                       className="form-control"
//                                       // required
//                                     />
//                                   </div>
//                                 </td>

//                                 {/* <td style={{}}>
//                                   <div className="form-area">
//                                     <input
//                                       style={{ marginLeft: '-22px' }}
//                                       value={field.discount}
//                                       onChange={(event) => handleChange(index, event)}
//                                       step="any"
//                                       name="discount"
//                                       type="number"
//                                       min={0}
//                                       className="form-control as"
//                                     />
//                                   </div>
//                                   {''}
//                                 </td> */}

//                                 <td style={{}}>
//                                   <div className="form-area">
//                                     <input
                                     
//                                       step="0.01"
//                                       value={field.cost}
//                                       onChange={(event) => handleChange(index, event)}
//                                       name="cost"
//                                       min={0}
//                                       type="number"
//                                       className="form-control"
//                                     />
//                                   </div>
//                                 </td>

//                                 {/* <td style={{}}>
//                                   <div className="form-area">
//                                     <input
//                                       //  style={{marginLeft:"-22px"}}
//                                       step="0.01"
//                                       value={field.discounted_cost}
//                                       onChange={(event) => handleChange(index, event)}
//                                       name="discounted_cost"
//                                       min={0}
//                                       type="number"
//                                       className="form-control as"
//                                     />
//                                   </div>
//                                 </td> */}
//                                 {/* 
//                                 <td style={{}}>
//                                   <div className="form-area">
//                                     <input
//                                       style={{ marginLeft: '-22px' }}
//                                       step="0.01"
//                                       value={field.cost_tax}
//                                       onChange={(event) => handleChange(index, event)}
//                                       name="cost_tax"
//                                       min={0}
//                                       type="number"
//                                       className="form-control as"
//                                     />
//                                   </div>
//                                 </td> */}

//                                 <td>
//                                   {index !== 0 && (
//                                     <button
//                                       type="button"
//                                       className="btn btn-outline-danger"
//                                       onClick={() => handleDelete(index)}
//                                     >
//                                       X
//                                     </button>
//                                   )}
//                                 </td>
//                               </tr>
//           </tbody>
//         </table>
//       </div>

//       <div class="modal-footer">
//         <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
//       </div>
//     </div>
//   </div>
// </div>

//     </div>
//   )
// }

import React from 'react'

const Addproduct = () => {
  return (
    <div>Addproduct</div>
  )
}

export default Addproduct